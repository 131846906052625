<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA SOBRE INVENTARIOS RESIDUOS
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-paste fa-3x"></i> <br>
                            <span class="lg-numero">{{listaInventarioResiduos.length}}</span>
                            <br>
                            <span class="text-muted">Inventarios residuos</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Inventarios Residuos</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('004-14001-INV','c') == 1" cols="6" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarInventarioResiduos = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br>Nuevo inventario residuos
                            </b-button>
                        </b-col>
                        <b-col cols="6" class="my-2">
                            <b-button @click="generarExcel()" block size="md" variant="custom">
                                <i style="inline-size: auto" class="fas fa-cloud-download-alt fa-3x pb-1"></i><br>Descargar inventarios
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Inventarios Residuos registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaInventarioResiduos" :fields="campoInventarioResiduos" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('004-14001-INV','u') == 1" @click="abrirModalActualizarInventarioResiduos(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('004-14001-INV','d') == 1" @click="eliminarInventarioResiduo(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalRegistrarInventarioResiduos">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo inventario residuos </span>
            </h6>
            <CButtonClose @click="modalRegistrarInventarioResiduos = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarInventarioResiduo)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="revisión" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Revisión:" class="mb-2">
                                <b-form-input v-model="datosInventarioResiduo.revision" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la revisión"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="denominacion" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Denominación:" class="mb-2">
                                <b-form-input v-model="datosInventarioResiduo.denominacionResiduo" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la denominación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <!-- <b-col md="6">
                        <validation-provider name="denominacion" rules="required" v-slot="{errors}">
                            <b-form-group label="Denominación residuo:" class="mb-2">
                                <v-select label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosInventarioResiduo.denominacionResiduo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosInventarioResiduo.denominacionResiduo" :options="comboDenomicacionResiduo" @search:blur="blurDenominacionResiduo">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col> -->
                    <b-col md="6">
                        <validation-provider name="CER" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="CER:" class="mb-2">
                                <b-form-input v-model="datosInventarioResiduo.cer" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el CER"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="RESP" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="RESP:" class="mb-2">
                                <b-form-input v-model="datosInventarioResiduo.resp" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el RESP"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="origen / área de generación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Origen / Área de generación:" class="mb-2">
                                <b-form-input v-model="datosInventarioResiduo.origenAreaGeneracion" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el origen / área"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observaciones" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosInventarioResiduo.observaciones" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese las observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarInventarioResiduos = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalActualizarInventarioResiduos">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Actualizar inventario residuos </span>
            </h6>
            <CButtonClose @click="modalActualizarInventarioResiduos = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarInventarioResiduo)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="revisión" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Revisión:" class="mb-2">
                                <b-form-input v-model="datosActualizarInventarioResiduo.revision" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la revisión"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <!-- <b-col md="6">
                        <validation-provider name="denominacion" rules="required" v-slot="{errors}">
                            <b-form-group label="Denominación residuo:" class="mb-2">
                                <v-select label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarInventarioResiduo.denominacionResiduo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarInventarioResiduo.denominacionResiduo" :options="comboDenomicacionResiduo" @search:blur="blurDenominacionResiduo">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col> -->
                    <b-col md="6">
                        <validation-provider name="denominacion" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Denominación:" class="mb-2">
                                <b-form-input v-model="datosActualizarInventarioResiduo.denominacionResiduo" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la denominación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="CER" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="CER:" class="mb-2">
                                <b-form-input v-model="datosActualizarInventarioResiduo.cer" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el CER"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="RESP" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="RESP:" class="mb-2">
                                <b-form-input v-model="datosActualizarInventarioResiduo.resp" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el RESP"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="origen / área de generación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Origen / Área de generación:" class="mb-2">
                                <b-form-input v-model="datosActualizarInventarioResiduo.origenAreaGeneracion" type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el origen / área"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observaciones" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarInventarioResiduo.observaciones" rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese las observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarInventarioResiduos = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import XLSX from 'sheetjs-style';

export default {
    data() {
        return {
            disabled: false,
            modalRegistrarInventarioResiduos: false,
            modalActualizarInventarioResiduos: false,

            totalRows: 1,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            campoInventarioResiduos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "revision",
                    label: "Revisión",
                    class: "text-center",
                },
                {
                    key: "denominacionResiduo",
                    label: "Denominación residuo",
                    class: "text-center",
                },
                {
                    key: "cer",
                    label: "CER",
                    class: "text-center",
                },
                {
                    key: "origenGeneracion",
                    label: "Origen / Área de generación",
                    class: "text-center",
                },
                {
                    key: "resp",
                    label: "RESP",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaInventarioResiduos: [],
            // comboDenomicacionResiduo: [{
            //         idDenominacionResiduo: '1',
            //         descripcion: 'kklasjda'
            //     },
            //     {
            //         idDenominacionResiduo: '2',
            //         descripcion: 'fgsad'
            //     },
            //     {
            //         idDenominacionResiduo: '3',
            //         descripcion: 'kklasdfgsjda'
            //     },
            //     {
            //         idDenominacionResiduo: '4',
            //         descripcion: 'dasdadweqe'
            //     }
            // ],
            datosInventarioResiduo: {
                idCliente: null,
                revision: '',
                denominacionResiduo: '',
                cer: '',
                origenAreaGeneracion: '',
                resp: '',
                observaciones: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs
            },
            datosActualizarInventarioResiduo: {
                idResiduoSGMA: null,
                idCliente: null,
                revision: '',
                denominacionResiduo: '',
                cer: '',
                origenAreaGeneracion: '',
                resp: '',
                observaciones: '',
                ejercicio: JSON.parse(localStorage.settings).yExcs
            }
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        blurDenominacionResiduo() {
            this.computedForm.refs.denominacion.validate();
        },
        abrirModalActualizarInventarioResiduos(param) {
            this.datosActualizarInventarioResiduo.idResiduoSGMA = param.item.idResiduoSGMA;
            this.datosActualizarInventarioResiduo.revision = param.item.revision;
            this.datosActualizarInventarioResiduo.denominacionResiduo = param.item.denominacionResiduo;
            this.datosActualizarInventarioResiduo.cer = param.item.cer;
            this.datosActualizarInventarioResiduo.origenAreaGeneracion = param.item.origenGeneracion;
            this.datosActualizarInventarioResiduo.resp = param.item.resp;
            this.datosActualizarInventarioResiduo.observaciones = param.item.observaciones;
            this.datosActualizarInventarioResiduo.ejercicio = param.item.ejercicio;

            this.modalActualizarInventarioResiduos = true;

        },
        registrarInventarioResiduo() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-inventario-residuo",
                    me.datosInventarioResiduo, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarInventarioResiduos = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarInventarioResiduos();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        actualizarInventarioResiduo() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-inventario-residuo",
                    me.datosActualizarInventarioResiduo, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalActualizarInventarioResiduos = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarInventarioResiduos();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        resetModalRegistrarInventarioResiduos() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosInventarioResiduo.revision = '',
                this.datosInventarioResiduo.denominacionResiduo = '',
                this.datosInventarioResiduo.cer = '',
                this.datosInventarioResiduo.origenAreaGeneracion = '',
                this.datosInventarioResiduo.resp = '',
                this.datosInventarioResiduo.observaciones = ''
        },
        resetModalActualizarInventarioResiduos() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

        },
        eliminarInventarioResiduo(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el inventario residuos?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-inventario-residuo", {
                                idResiduoSGMA: param.item.idResiduoSGMA
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarInventarioResiduos();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        listarInventarioResiduos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-inventario-residuos", {
                        params: {
                            idCliente: me.datosInventarioResiduo.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs
                        },
                    }
                )
                .then(function (response) {
                    me.listaInventarioResiduos = response.data;
                    me.totalRows = me.listaInventarioResiduos.length

                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        generarExcel() {

            let datosInventario = [];
            let datosContolInventario = [];

            for (const i in this.listaInventarioResiduos) {
                datosInventario.push({
                    revision: this.listaInventarioResiduos[i].revision,
                    denominacionResiduo: this.listaInventarioResiduos[i].denominacionResiduo,
                    cer: this.listaInventarioResiduos[i].cer,
                    origenGeneracion: this.listaInventarioResiduos[i].origenGeneracion,
                    resp: this.listaInventarioResiduos[i].resp,
                    observaciones: this.listaInventarioResiduos[i].observaciones,
                })
            }

            for (let index = 0; index < 10; index++) {
                datosContolInventario.push({
                    residuo: '',
                    procedencia: '',
                    cantidad: '',
                    fechaRecogida: '',
                    observaciones: '',
                })
            }
            
            let inventarioResiduosWS = [];
            let controlInventarioWS = [];

            if (this.listaInventarioResiduos.length > 0) {
                inventarioResiduosWS = XLSX.utils.json_to_sheet(datosInventario, {
                    origin: 'A4'
                });

                XLSX.utils.sheet_add_aoa(inventarioResiduosWS, [
                    ['INVENTARIO DE RESIDUOS']
                ], {
                    origin: 'A2'
                });

                inventarioResiduosWS['A4'].v = 'REV';
                inventarioResiduosWS['B4'].v = 'DENOMINACIÓN DEL RESIDUO';
                inventarioResiduosWS['C4'].v = 'CER';
                inventarioResiduosWS['D4'].v = 'ORIGIEN / ÁREA DE GENERACIÓN';
                inventarioResiduosWS['E4'].v = 'RESP.';
                inventarioResiduosWS['F4'].v = 'OBSERVACIONES';

                inventarioResiduosWS[`A4`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    },
                    font: {
                        bold: true
                    },
                    fill: {
                        patternType: "solid",
                        fgColor: {
                            rgb: "FF0000"
                        },
                    }
                };
                inventarioResiduosWS[`B4`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    },
                    font: {
                        bold: true
                    },
                    fill: {
                        patternType: "solid",
                        fgColor: {
                            rgb: "FF0000"
                        },
                    }
                };
                inventarioResiduosWS[`C4`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    },
                    font: {
                        bold: true
                    },
                    fill: {
                        patternType: "solid",
                        fgColor: {
                            rgb: "FF0000"
                        },
                    }
                };
                inventarioResiduosWS[`D4`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    },
                    font: {
                        bold: true
                    },
                    fill: {
                        patternType: "solid",
                        fgColor: {
                            rgb: "FF0000"
                        },
                    }
                };
                inventarioResiduosWS[`E4`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    },
                    font: {
                        bold: true
                    },
                    fill: {
                        patternType: "solid",
                        fgColor: {
                            rgb: "FF0000"
                        },
                    }
                };
                inventarioResiduosWS[`F4`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                    alignment: {
                        horizontal: 'center'
                    },
                    font: {
                        bold: true
                    },
                    fill: {
                        patternType: "solid",
                        fgColor: {
                            rgb: "FF0000"
                        },
                    }
                };

                let x = 5;
                for (const j in datosInventario) {
                    inventarioResiduosWS[`A${x}`].s = {
                        border: {
                            top: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            bottom: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            right: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            left: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                        },
                    };
                    inventarioResiduosWS[`B${x}`].s = {
                        border: {
                            top: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            bottom: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            right: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            left: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                        },
                    };
                    inventarioResiduosWS[`C${x}`].s = {
                        border: {
                            top: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            bottom: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            right: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            left: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                        },
                    };
                    inventarioResiduosWS[`D${x}`].s = {
                        border: {
                            top: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            bottom: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            right: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            left: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                        },
                    };
                    inventarioResiduosWS[`E${x}`].s = {
                        border: {
                            top: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            bottom: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            right: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            left: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                        },
                    };
                    inventarioResiduosWS[`F${x}`].s = {
                        border: {
                            top: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            bottom: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            right: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                            left: {
                                style: "thin",
                                color: {
                                    auto: 1
                                }
                            },
                        },
                    };
                    x++;
                }
            }

            controlInventarioWS = XLSX.utils.json_to_sheet(datosContolInventario, {
                origin: 'A4'
            });

            XLSX.utils.sheet_add_aoa(controlInventarioWS, [
                ['INVENTARIO Y CONTROL DE RESIDUOS']
            ], {
                origin: 'A2'
            });

            controlInventarioWS['A4'].v = 'RESIDUO';
            controlInventarioWS['B4'].v = 'PROCEDENCIA';
            controlInventarioWS['C4'].v = 'CANTIDAD';
            controlInventarioWS['D4'].v = 'FECHA RECOGIDA';
            controlInventarioWS['E4'].v = 'OBSERVACIONES';

            controlInventarioWS[`A4`].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                },
                alignment: {
                    horizontal: 'center'
                },
                font: {
                    bold: true
                },
                fill: {
                    patternType: "solid",
                    fgColor: {
                        rgb: "FF0000"
                    },
                }
            };
            controlInventarioWS[`B4`].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                },
                alignment: {
                    horizontal: 'center'
                },
                font: {
                    bold: true
                },
                fill: {
                    patternType: "solid",
                    fgColor: {
                        rgb: "FF0000"
                    },
                }
            };
            controlInventarioWS[`C4`].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                },
                alignment: {
                    horizontal: 'center'
                },
                font: {
                    bold: true
                },
                fill: {
                    patternType: "solid",
                    fgColor: {
                        rgb: "FF0000"
                    },
                }
            };
            controlInventarioWS[`D4`].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                },
                alignment: {
                    horizontal: 'center'
                },
                font: {
                    bold: true
                },
                fill: {
                    patternType: "solid",
                    fgColor: {
                        rgb: "FF0000"
                    },
                }
            };
            controlInventarioWS[`E4`].s = {
                border: {
                    top: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    bottom: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    right: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                    left: {
                        style: "thin",
                        color: {
                            auto: 1
                        }
                    },
                },
                alignment: {
                    horizontal: 'center'
                },
                font: {
                    bold: true
                },
                fill: {
                    patternType: "solid",
                    fgColor: {
                        rgb: "FF0000"
                    },
                }
            };

            let xx = 5;
            for (const j in datosContolInventario) {
                controlInventarioWS[`A${xx}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                };
                controlInventarioWS[`B${xx}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                };
                controlInventarioWS[`C${xx}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                };
                controlInventarioWS[`D${xx}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                };
                controlInventarioWS[`E${xx}`].s = {
                    border: {
                        top: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        bottom: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        right: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                        left: {
                            style: "thin",
                            color: {
                                auto: 1
                            }
                        },
                    },
                };
                xx++;
            }

            inventarioResiduosWS[["A2"]].s = {
                font: {
                    sz: 14,
                    bold: true
                },
                alignment: {
                    horizontal: 'center'
                },

            };

            controlInventarioWS[["A2"]].s = {
                font: {
                    sz: 14,
                    bold: true
                },
                alignment: {
                    horizontal: 'center'
                },

            };

            let inventarioResiduosWSMerges = [{
                s: {
                    r: 1,
                    c: 0
                },
                e: {
                    r: 1,
                    c: 5
                },
            }, ];

            let controlInventarioWSMerges = [{
                s: {
                    r: 1,
                    c: 0
                },
                e: {
                    r: 1,
                    c: 4
                },
            }, ];

            let inventarioResiduosWSCols = [{
                    wch: 10
                },
                {
                    wch: 30
                },
                {
                    wch: 10
                },
                {
                    wch: 30
                },
                {
                    wch: 10
                },
                {
                    wch: 40
                },
            ];
            let controlInventarioWSWSCols = [{
                    wch: 20
                },
                {
                    wch: 20
                },
                {
                    wch: 15
                },
                {
                    wch: 15
                },
                {
                    wch: 40
                },
            ];

            inventarioResiduosWS['!cols'] = inventarioResiduosWSCols;
            controlInventarioWS['!cols'] = controlInventarioWSWSCols;
            inventarioResiduosWS['!merges'] = inventarioResiduosWSMerges;
            controlInventarioWS['!merges'] = controlInventarioWSMerges;

            let wb = XLSX.utils.book_new()

            XLSX.utils.book_append_sheet(wb, inventarioResiduosWS, 'Inventario de Residuos');
            XLSX.utils.book_append_sheet(wb, controlInventarioWS, 'Control Residuos')

            XLSX.writeFile(wb, 'inventario-residuos.xlsx')
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
    },
    watch: {
        modalRegistrarInventarioResiduos: function (val) {
            if (val == false) {
                this.resetModalRegistrarInventarioResiduos();
            }
        },
        modalActualizarInventarioResiduos: function (val) {
            if (val == false) {
                this.resetModalActualizarInventarioResiduos();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosInventarioResiduo.idCliente = this.datosActualizarInventarioResiduo.idCliente = user.uidClient;
            this.listarInventarioResiduos();
        }
    }

}
</script>
